<template>
    <div style="background:#fff;">
        <div v-if="items && items.length>0">
            <div class="flex" style="min-height: 670px;">
                <div v-for="(item,idx) in items" :key="idx"  class="item">
                    <div @click="gotoDetail(item.id)" style="width:230px;height:230px;margin-right:40px;cursor:pointer;">
                        <div v-if="item.photo_render && item.photo_render.length>0" class="bg_img"
                        :style="{backgroundImage: 'url(' + imgUrl+item.photo_render[0]+'!width_250px' + ')'}"></div>
                    </div>
                    <div class="detail">
                        <div @click="gotoDetail(item.id)" class="item-name ellipsis">{{item.name}}</div>
                        <div class="item-series flex">
                            <div>{{item.style.name}}</div>
                            <div>{{item.cat.name}}</div>
                        </div>
                        <div class="item-price">￥{{item.price | priceFilt}}</div>
                        <div style="font-size:12px;margin-bottom:8px;" class="flex flex-align-center">
                            <!-- <div style="margin-right: 15px;">已有{{item.design_market_n_visited}}人查看</div> -->
                            <!-- <div><el-button @click.stop="gotoSign(item)" size="mini" type="primary">我要购买</el-button></div> -->
                        </div>
                        <div class="item-avatar flex flex-align-center">
                            <div style="width:24px;height:24px;border-radius:50%;overflow:hidden;margin-right:8px;">
                                <div v-if="item.design_comp && item.design_comp.logo" class="bg_img"
                                    :style="{backgroundImage: 'url(' + imgUrl+item.design_comp.logo + ')'}"></div>
                                <div v-else class="bg_img"
                                :style="{backgroundImage: 'url(' + staticUrl+'design/placeholder/zhanweilogo%402x.jpg!width_250px' + ')'}"></div>
                            </div>
                            <div class="flex-1 ellipsis" v-if="item.design_comp && item.design_comp.name">{{item.design_comp.name}}</div>
                        </div>

                    </div>
                </div>
                <!-- <el-row :gutter="20">
                    <el-col v-for="(item,idx) of items" :key="idx" :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        
                        
                    </el-col>
                </el-row> -->
            </div>
            <div style="padding:10px 0 20px;text-align: left;">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.page" :page-size="queryOpt.pagesize" :total="total"></el-pagination>
            </div>
        </div>
        <!-- <div v-else style="padding: 30px 0;text-align: center;">
            <div><i style="font-size: 60px;color: #ccc;" class="iconfont icon-meiyougengduo"></i></div>
            <div>暂无数据，请去发布</div>
        </div> -->

    </div>
</template>

<script>
    import _ from 'lodash';
    import { IMG_URL_PRE,STATIC_URL_PRE } from '@/config';
    import { marketPriceList } from '@/service/factory';
    export default {
        components: {
            
        },
        data() {
            return {
                imgUrl: IMG_URL_PRE,
                staticUrl:STATIC_URL_PRE,
                popVisible: false,
                popTitle: null,
                queryOpt: {
                    pagesize: 20,
                    page: 1,
                    sort_by: 0,
                    sort_dir: 0,
                    cat_ids: [],
                },
                total:0,
                items: [],
            }
        },
        watch: {
            queryOpt: {
                handler() {
                    this.debounceRefreshItems();
                },
                deep: true
            },
        },
        async created() {
            this.debounceRefreshItems = _.debounce(this.refreshItems, 500);
        },
        mounted() {
            this.refreshItems();
        },
        methods: {
            gotolink(path){
                this.$router.push(path);
            },
            gotoDetail(id) {
                this.$router.push("/factory/gmsj/bqxs/detail/"+id);
            },
            refreshItems() {
                console.log('refreshItems');
                this.items = [];
                marketPriceList(this.queryOpt).then(rst => {
                    if (rst.list && rst.list.length>0) {
                        this.total=rst.total;
                        this.items = rst.list;
                    }
                    console.log(this.items);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    
    .item{
        width: 230px;
        height: 361px;
        overflow: hidden;
        border:1px solid rgba(204,204,204,1);
        margin: 10px ;
        transform: scale(1);
        transition: all 0.3s;
    }
    .item:hover {
      border: 1px solid rgba(80, 116, 238, 1);
      transform: scale(1.064);
    }
    /* .item:nth-child(4n) {
        margin-right: 0;
    } */
    .item .detail {
        padding:8px 13px;
        position: relative;
        font-size: 14px;
        color: #808080;
    }
    .item .item-name {
        font-size: 16px;
        font-weight: bold;
        color: rgba(41, 41, 41, 1);
        line-height:22px;
        margin-bottom: 4px;
        cursor:pointer;
    }
    .item-series {
        font-size: 14px;
        color: #808080;
        line-height: 18px;
        margin-bottom: 4px;
    }
    .item-series>div {
        padding: 0 10px;
        border-right: 1px solid #CCCCCC;
    }
    .item-series>div:first-child {
        padding-left: 0;
    }
    .item-series>div:last-child {
        padding-right: 0;
        border-right: none;
    }
    .item-price {
        font-size: 20px;
        color: #EE1818;
        line-height: 28px;
    }
    .item-avatar {
        border-top: 1px solid #E6E6E6;
        padding-top: 4px;
    }
</style>